import React, { Component } from "react";
import {Link} from "react-router-dom";
import "./styles.css";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Full-Stack Dev MERN-Stack',
        title: 'CyberSecurity Marketplace / Platform'
    },
    {
        image: 'image-2',
        category: 'Mobile App Development MERN-Stack',
        title: 'MakeNDate Dating App (Mobile)'
    },
    {
        image: 'image-3',
        category: 'Website Full-Stack MERN Dev',
        title: 'MakeNDate dating app ~ website'
    },
    {
        image: 'image-4',
        category: 'Mobile App (Partially Dev.)',
        title: 'Mobile App Advertising On 18-Wheelers'
    },
    {
        image: 'image-3',
        category: 'Website Client Upwork',
        title: 'Musical instruments auction website'
    },
    {
        image: 'image-4',
        category: 'No Data Available',
        title: 'Nothing is available for this project. Empty.'
    }
]

class PortfolioList extends Component{

    calculateRedirect = (key) => {
        switch (key) {
            case 0:
                return "/portfolio-details-cyber";
                break;
            case 1:
                return "/portfolio-details-makendate";
                break;
            case 2:
                return "/portfolio-details-makendate-website";
                break;
            case 3:
                return "/ad-truck/app/porfolio";
                break;
            case 4:
                return "/portfolio-details-upwork-auction";
                break;
            case 5:
                return "/portfolio-details-makendate";
                break;
            case 6:
                return "/portfolio-details-makendate";
                break;
            default:
                break;
        }
    };

    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return (
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail portfolio-${index}-${index}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner portfolio-tinted">
                                    <p>{value.category}</p>
                                    <h4><Link to={this.calculateRedirect(index)}>{value.title}</Link></h4>
                                    <div className="portfolio-button">
                                        <Link className="rn-btn" to={this.calculateRedirect(index)}>View Details</Link>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={this.calculateRedirect(index)}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;