import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import "./styles.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Full-stack MERN stack JS engineer/dev',
        title: 'Hello, I’m <span>JEREMY</span> Welcome To My Page!',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    let title = 'About Me',
        description = 'A solution-focused and technically competent Full-Stack Software Developer, highly experienced in JavaScript, jQuery, React, React Native, Redux, MERN, MEAN, REST APIs, AWS, database administration, and other technologies, implementing the best strategies that improve workflows, services, and teams. Having architected and developed technical solutions, I excel at driving all phases of projects by transforming simple requirements into quantifiable growth and revenue, building and configuring secure products, visualizing, and delivering expected results. Committed professional, highly organized, capable to work under pressure with progressive teams to implement solutions that perform.';
    const PostList = BlogContent.slice(0 , 4);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        vertical: false,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true,
        rows: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            }
          ]
    };
    return (
        <div>
            <Helmet pageTitle="Jeremy Blong - Portfolio" />
            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                {/* <div className="slider-wrapper"> */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                            <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--111" key={index}>
                                <div className='slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image--111-front'>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">

                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span className='text-white-custom'>{value.category}</span> : ''}
                                                {value.title ? <h1 className='text-white-custom title' dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={require("../assets/me-3.jpg")} alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">My Technological Stack</h2>
                                    <p>I am a versatile professional proficient in the MERN (MongoDB, Express, React/React Native, Node.js) stack, covering the entire spectrum of full-stack JavaScript development. With extensive hands-on experience, I've mastered MongoDB for robust data storage, utilized Express for streamlined server-side operations, and employed React/React Native to craft dynamic and responsive user interfaces. My Node.js expertise enables seamless communication between front and back ends, ensuring optimal performance and a cohesive user experience. This reflects my commitment to staying at the forefront of technology, navigating full-stack development with precision and innovation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            {/* End Service Area  */} 

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Latest Projects</h2>
                                        <p>
                                            My latest projects showcase innovative solutions, blending creativity with technical prowess. From dynamic web interfaces to cutting-edge applications, each project reflects a commitment to excellence in design and functionality.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Blog Area */}
            <div id="blog" className="fix">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Latest News/blogs</h2>
                                    <p>      
                                        Keep yourself informed with the newest updates and valuable insights available on my blog. Delve into a diverse range of topics, spanning from the latest tech trends to groundbreaking industry innovations. Immerse yourself in timely and informative content to stay abreast of the dynamic and ever-evolving landscape.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Slider className="row mt--60 mt_sm--40" {...settings}>
                                {PostList.map((value , i ) => (
                                    <div key={i}>
                                        <div style={{ margin: 22.25 }} className="blog blog-style--123">
                                            <div className="thumbnail thumbnail-fit">
                                                <a>
                                                    <img className="w-100" src={value.image} alt="Blog Images"/>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <p className="blogtype">{value.category}</p>
                                                <h4 className="title"><a>{value.title}</a></h4>
                                                <div className="blog-btn">
                                                    <Link className="rn-btn text-white" to={value.link}>Read More</Link> {/* blog-details */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>    
                    </div>    
                </div>
            </div>
            <div id="blog" className="fix">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Educational YouTube Content / Videos</h2>
                                    <p>      
                                        Welcome to my Educational YouTube Content on Software Development, a dynamic space where coding enthusiasts, aspiring developers, and tech aficionados come together to embark on a transformative learning journey. My videos are meticulously crafted to demystify the world of software development, offering insightful tutorials, hands-on coding sessions, and in-depth explorations of the latest technologies and programming languages. Whether you're a beginner taking your first steps into the coding universe or an experienced developer seeking to sharpen your skills, our content covers everything from fundamental concepts to advanced techniques
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Slider className="row mt--60 mt_sm--40" {...{
                                dots: true,
                                infinite: true,
                                speed: 500,
                                slidesToShow: 2,
                                vertical: false,
                                slidesToScroll: 1,
                                adaptiveHeight: true,
                                autoplay: false,
                                arrows: true,
                                rows: 1,
                                responsive: [
                                    {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                        infinite: true,
                                        dots: true
                                    }
                                    },
                                    {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        initialSlide: 1
                                    }
                                    },
                                    {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        initialSlide: 1
                                    }
                                    }
                                ]
                            }}>
                                <div>
                                    <iframe width="98.25%" height="315" src="https://www.youtube.com/embed/Y4d6Ux9S3b8?si=SWWV8YQz-8Cx1_HK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div>
                                    <iframe width="98.25%" height="315" src="https://www.youtube.com/embed/b12T35G_VLo?si=MC3GezxvQaro0fDj" title="YouTube video player"   frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div>
                                    <iframe width="98.25%" height="315" src="https://www.youtube.com/embed/TMkKUv1CneQ?si=pe5NBPSWhqNmJLZ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div>
                                    <iframe width="98.25%" height="315" src="https://www.youtube.com/embed/uOJbTU7HW50?si=Z24iUtoNLFV01Z0O" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/UHurP576l4M?si=oTftx6ZWd8dB9mKJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </Slider>
                        </div>    
                    </div>    
                </div>
            </div>
            {/* End Blog Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactOne />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default PortfolioLanding;
