import React, { Component } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../component/header/Header";
import Footer from "../../../component/footer/Footer";
import "./styles.css";
import { Link } from "react-router-dom";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class AdTruckPortfolio extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image--12345"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">MakeNDate Dating Platform (Website View)</h2>
                                    <p>                             
                                        🌟 Welcome to the heart of modern connections – the Makendate Mobile Dating App website! 📱 Discover a revolutionary approach to dating that transcends the ordinary. Our platform goes beyond swipes, introducing a dynamic realm where live in-person meetups, immersive video calling, and engaging messaging converge in a vibrant social space. Dive into a community-driven experience that values genuine connections, offering not only romantic possibilities but also avenues for mentorship, networking, and friendship. Explore the unique buy-sell clothing feature, showcasing your style and connecting over shared fashion interests. Join live-streams and events that add a social flair to your dating journey, making every interaction memorable. Makendate isn't just an app; it's a lifestyle. Embrace the future of social dating – where every connection is an opportunity for something extraordinary. 🚀🌐✨
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>MakeNDate Dating</h2>
                                        <p className="subtitle">
                                            🌟 Welcome to the Makendate Website – your portal to the innovative blockchain side of our platform, with a special focus on our Initial Coin Offering (ICO)! 💻 Discover a revolutionary approach to blockchain technology, where information meets innovation in a vibrant digital landscape. Our website goes beyond the conventional, offering detailed insights into the blockchain architecture that powers Makendate, including the intricacies of our ICO.
                                            <br />
                                            <br />
                                            💡 Dive into a realm of possibilities as the Makendate Website delves into the core of blockchain technology. It's not just about transactions; it's a community-driven experience that elucidates the transformative potential of our blockchain framework. Explore the ICO section for comprehensive details on our Initial Coin Offering, understanding the unique features and benefits it brings to our platform.
                                            <br />
                                            <br />
                                            🔗 Break the barriers with our informative content, designed to provide a clear understanding of the blockchain side of Makendate. Whether you're a tech enthusiast or a potential investor, our website brings you closer to the intricacies of our blockchain architecture and the exciting opportunities presented by our ICO.
                                            <br />
                                            <br />
                                            📚 Stay engaged with our comprehensive information hub, designed for in-depth exploration of the blockchain technology underpinning Makendate. But it's not just about the technicalities; our website serves as a hub for fostering connections, networking, and collaboration in the dynamic realm of blockchain innovation.
                                            <br />
                                            <br />
                                            🌐 Elevate your understanding of Makendate with a focus on blockchain technology and the ICO. Uncover the potential for transformative connections in the digital sphere, where information transcends beyond the virtual world, providing you with the knowledge needed to engage with the blockchain future.
                                            <br />
                                            <br />
                                            🚀 Join us in this journey of exploration and innovation with Makendate – where blockchain meets the future of social connectivity! 💻🌐✨    
                                        </p>
                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Type</span>
                                                <h4>Full-Stack JavaScript (MERN Stack)</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Mobile/App</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Technologies</span>
                                                <h4>Express.JS, Node.JS, React.JS, MongoDB, AWS S3, API's/REST, Etc...</h4>
                                            </div>
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <a href="https://www.weshopforyouonline.com" target="_blank" className="thumb position-relative mb--30">
                                            <img src={require("../../../assets/makendate-website.png")} alt="Portfolio Images"/>
                                            {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='2nZA6yGR8Ng' onClose={() => this.setState({isOpen: false})} /> */}
                                            {/* <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                        </a>
{/*                                         
                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber1.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber3.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber5.png")}  alt="Portfolio Images"/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Other Related Work</span>
                                    <h2>More Of My Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/portfolio-details-cyber">
                                            <img src={require("../../../assets/cyber1.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/portfolio-details-cyber">The Hacker Marketplace</Link></h4>
                                        <span className="category">Web Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/ad-truck/app/porfolio">
                                            <img src={require("../../../assets/adtruck.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/ad-truck/app/porfolio">Ad-Truck Semi-Advertising</Link></h4>
                                        <span className="category">Mobile Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default AdTruckPortfolio;
